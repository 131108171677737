import React, { Component } from 'react';
import styles from "./island.module.scss"

class Island extends Component {
    render() {
        return (
            <div className={styles.islandGrid}>
                <div className={styles.islandsWrapper}>
                    <svg id={styles.Capa1} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 360" width="400">
                        <path d="M180,240.081c-6.806,0-13.465,0.396-19.929,1.149l-5.771,28.85h-20.832 l-4.41-22.051C93.957,259.722,70,283.119,70,310.08h110C180,271.421,180,278.969,180,240.081z"
                              fill="#ffd6a0" />
                        <path d="M290,310.08c0-27.399-24.742-51.117-60.778-62.611 c1.121,3.662,1.064,7.713-0.456,11.551c-3.559,8.986-13.729,13.385-22.715,9.827c-8.985-3.559-13.385-13.727-9.826-22.714 c0.742-1.875,1.785-3.536,3.032-4.981c-6.253-0.702-12.687-1.07-19.257-1.07c0,38.889,0,31.34,0,69.999H290z"
                              fill="#ffc170" />
                        <path d="M154.301,270.08l5.771-28.85l4.646-23.232h-10.051l14.169-43.65l-9.218-1.079 l13.757-27.363c-0.834-0.199-1.667-0.439-2.488-0.764c-5.679-2.249-9.582-7.037-10.959-12.543 c-4.773,3.07-10.897,3.888-16.576,1.639c-1.078-0.427-2.085-0.955-3.031-1.553l-2.709,38.008l-10.156-1.188l3.929,48.494h-8.332 l6.007,30.03l4.41,22.051H154.301z"
                              fill="#a0815c" />
                        <path d="M156.773,100.342c5.68,2.249,9.584,7.038,10.961,12.544l0,0 c8.165-20.619,26.556-67.061,27.167-68.605c-2.791-3.572-6.26-7.017-10.568-10.167c-46.927-34.311-90.143,4.371-90.143,4.371 c25.068-0.276,42.009,6.51,53.427,15.32c-9.428-0.748-20.257-0.112-32.118,3.124C50.354,74.71,50,151.882,50,151.882 c28.617-34.059,59.689-39.786,82.141-37.899c0.211-1.144,0.529-2.283,0.974-3.405C136.821,101.218,147.416,96.636,156.773,100.342z"
                              fill="#64c37d" />
                        <path d="M184.311,111.247c9.359,3.705,13.943,14.299,10.237,23.659 c-0.444,1.121-0.993,2.17-1.62,3.148c17.656,13.994,36.383,39.442,33.924,83.859c0,0,52.576-56.492,17.267-114.052 c-6.429-10.48-13.888-18.357-21.272-24.266c14.355,1.394,31.35,8.046,49.432,25.41c0,0-5.018-57.782-62.713-64.901 c-5.297-0.653-10.183-0.519-14.663,0.175c-0.611,1.545-19.002,47.986-27.167,68.605 C172.508,109.815,178.632,108.998,184.311,111.247z"
                              fill="#4b925e" />
                        <path d="M159.928,132.599c1.377,5.506,5.28,10.294,10.959,12.543 c0.821,0.325,1.654,0.564,2.488,0.764c7.539,1.806,15.399-1.382,19.553-7.852c0.627-0.979,1.176-2.027,1.62-3.148 c3.706-9.36-0.878-19.954-10.237-23.659c-5.679-2.249-11.803-1.432-16.576,1.639l0,0c-1.377-5.506-5.281-10.295-10.961-12.544 c-9.357-3.705-19.952,0.876-23.659,10.236c-0.444,1.122-0.763,2.262-0.974,3.405c-1.354,7.297,1.898,14.736,8.18,18.702 c0.946,0.598,1.953,1.126,3.031,1.553C149.03,136.487,155.154,135.67,159.928,132.599z"
                              fill="#50412e" />
                        <path d="M206.051,268.846c8.985,3.558,19.156-0.841,22.715-9.827 c1.52-3.838,1.577-7.889,0.456-11.551c-1.504-4.913-5.137-9.125-10.284-11.163c-7.11-2.816-14.95-0.635-19.681,4.846 c-1.247,1.445-2.29,3.106-3.032,4.981C192.666,255.119,197.065,265.288,206.051,268.846z"
                              fill="#50412e" />
                    </svg>
                </div>
            </div>
        );
    }
}

export default Island;